import React from 'react'
import Navbar from '../Navbar';
function projects() {
    return (
        <div>
            
        </div>
    )
}

export default projects
