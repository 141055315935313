import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import './Home.css'
import '../Cards.css'
import Footer from '../Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';

import Weather from '../../components/pages/projects/dev/Weather.js';




import { Link } from 'react-router-dom';

import {motion} from 'framer-motion';

const transition={duration: 2, ease: [0.43, 0.13, 0.23, 0.96]};



function Home() {



    const [isVisible, setVisible] = React.useState(false);
  useEffect(() => {
  Aos.init({
    duration: 600,
  
    
    easing: 'ease',
   
  });
  }, []);



  
    return (
        <div>
        <div className="page-container">
                 
          <Navbar/>
         
          <div className='section'>
                <h1 className='home-tag blue' data-aos="fade-right">Work </h1>

                    <motion.div 
                    whileHover={{scale:1.04 }}
                        fade exit={{opacity:50}} 
                        transition={transition}
                        leave={{opacity:0}}
                        className='card'> 
                        
                            <div className='card-left'>
                            <div className=' image-container-chargergogo'>
                                <img src= './images/chargergogo/logo.png' className='thumbnail-logo'/> 
                            </div>
                            </div>

                            <div className='card-right'>
                            <div className='image-detail-container'>
                                <div className="title">
                                <h1 className='card-title'> ChargerGoGo  </h1>   
                                <h1 className='card-subtitle'>Contact for more information</h1> 
                                <div className='card-tag-layout'>
                                    <div className='card-tag'> UI/UX</div>

                              

                                </div>
                                
                            
                                </div>

                            </div>

                            </div>
                        
                    </motion.div>


                    <motion.div 
                    whileHover={{scale:1.04 }}
                        fade exit={{opacity:50}} 
                        transition={transition}
                        leave={{opacity:0}}
                        className='card'> 
                        
                        <Link to ='/projects/getcoins'>
                            <div className='card-left'>
                            <div className=' image-container-getcoins'>
                                <img  src= './images/getcoins/card/logo.png' className='thumbnail-logo'/> 
                            </div>
                            </div>




                        

                            <div className='card-right'>
                            <div className='image-detail-container'>
                                <div className="title">
                                <h1 className='card-title'> GetCoins  </h1>   
                                <h1 className='card-subtitle'>Mobile App Redesign</h1> 
                                <div className='card-tag-layout'>
                                    <div className='card-tag'> UI/UX</div>

                                </div>
                                
                            
                                </div>

                            </div>

                            </div>
                        
                        </Link>
                    </motion.div>


            </div> 


      
    

            <div className='section'>
                <h1 className='home-tag blue' data-aos="fade-right">Case Studies </h1>

                <motion.div 
                        whileHover={{scale:1.04 }}
                        fade exit={{opacity:50}} 
                        transition={transition}
                        leave={{opacity:0}}
                        className='card'> 
                        
                        <Link to ='/projects/okayamadenim'>

                            <div className='card-left'>
                            <div className=' image-container-OD'>
                                <img
                                src= './images/od/OD1.png' 
                                className='thumbnail-image'/> 
                            </div>

                            </div>
                            

                        

                            <div className='card-right'>

                            <div className='image-detail-container'>
                                <div className="title">
                                <h1 className='card-title'> Okayama Denim  </h1>   
                                <h1 className='card-subtitle'>Ecommerce App</h1>  
                                <div className='card-tag-layout'>
                                    <div className='card-tag'> UI</div>

                                    <div className='card-tag'> Concept</div>

                                </div>
                                </div>

                            </div>

    </div>

                        </Link>

                        </motion.div>

                    <motion.div 
                    whileHover={{scale:1.04 }}
                        fade exit={{opacity:50}} 
                        transition={transition}
                        leave={{opacity:0}}
                        className='card'> 
                        
                        <Link to ='/projects/Backpack'>
                            <div className='card-left'>
                            <div className=' image-container-backpack'>
                                <img src= './images/backpack/v2/hero.png' className='thumbnail-image-small'/> 
                            </div>

                            </div>


                        

                            <div className='card-right'>
                            <div className='image-detail-container'>
                                <div className="title">
                                <h1 className='card-title'> Backpack Finder   </h1>
                                <h1 className='card-subtitle'> Ecommerce Website</h1> 
                                <div className='card-tag-layout'>
                                    <div className='card-tag'> UI/UX</div>
                                    <div className='card-tag'> Concept</div>
                          
                                </div>
                                
                            
                                </div>

                            </div>

                            </div>
                        
                        </Link>

                         </motion.div>
            </div> 
              


            <div className='section'>
                <h1 className='home-tag blue' data-aos="fade-right">Resources</h1>
                    <div className='card'> 
                            
                                <div className='card-left'>
                                <div className='image-container-api'>
                                    <Weather className='thumbnail'/>
                                </div>

                                </div>
                            
                                        <div className='card-right'>
                                <div  className='image-detail-container'>
                                    <div
                                        transition={transition}
                                    className="title">
                                    <h1 className='card-title'> Weather</h1>   
                                    <h1 className='card-subtitle'> API Call</h1> 
                                    <div className='card-tag-layout'>
                                    <div className='card-tag'> Dev</div>
                          
                                </div>
                                    </div>

                                </div>
                                
                                        </div>

                                

                            </div>


            </div>

            <Footer/>

            </div> 

        </div>

      
        
    )
}

export default Home
