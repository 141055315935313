import React from 'react'

const NotFound = () => {
    return (
        <div>
            <h2>
               404 Page Not found! 
            </h2>
        </div>
    )
}

export default NotFound;