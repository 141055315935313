import React from 'react';
import {useState} from 'react'
import './Apis.css';
import './Weather.css';

import Weather from './Weather';
import Translater from './Translater';

import axios from 'axios';

import ExchangeRate from './ExchangeRate';

import Navbar from '../../../Navbar';
import Footer from '../../../Footer';

import CurrensyConverter from './CurrensyConverter';


//Convert into grid

function Apis() {
  
  return <div>
<div className="page-container">
    <Navbar/>

          <Weather/>

              {/* <div className='row'> */}

              {/* <div className='api-card'>

              <CurrensyConverter/>
              <Translater/>

              </div> */}

              {/* </div> */}
    </div>


<Footer/> 
  </div>;


}

export default Apis;
