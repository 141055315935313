import React from 'react';
import './Weather.css';
import {useState} from 'react'



function Weather() {

const weatherapiKey='507032a18ab471bb36278e9e6efd6100'


const [weatherData,setWeatherData]=useState({})
const [futureweatherData,setfutureWeatherData]=useState({})

const [city,setCity]=useState({})


const searchWeather= (event)=>{

    if (event.key === "Enter" && weatherData.name != undefined) {
      
      fetch (`https://api.openweathermap.org/data/2.5/weather?q=${city}&units=imperial&appid=${weatherapiKey}`).then(
      response => response.json() 
      ).then(
        data => {
        setWeatherData(data)
        setCity("")

      }
  )
  }
}

var initialCity=['Denver','New York', 'Los Angeles','Chicago','Singapore'];
var randomCity = initialCity[Math.floor(Math.random() * initialCity.length)];
var tempNum=0;
// // Initial constant weather
  if (weatherData.name === undefined && tempNum === 0){
        tempNum=1;
    fetch (`https://api.openweathermap.org/data/2.5/weather?q=${randomCity}&units=imperial&appid=${weatherapiKey}`).then(
      response => response.json() 
    ).then(
      data => {
      setWeatherData(data)
      setCity("")
    }
  )
}



const getIcon =  weatherData.main && weatherData.weather[0].icon
const icon = "http://openweathermap.org/img/wn/" + getIcon + "@2x.png";

//Round temp
const temp= Math.round(parseFloat(weatherData.main && weatherData.main.temp));


  return <div>

      <div className='weather-card'>
        <div className='weather-body'>
            <h4 className='current-weather'> Current Weather </h4>
              <div className='input-box' >

                <input class='weather-input'
                value={city}
                placeholder='Enter Location . . . '
                onChange={(e) => setCity(e.target.value)}
                onKeyPress={searchWeather}
                />

              </div>



     <img src={icon} class='w-icon'/>

     <div className='weather-data'>
        <h4 className='city'>{weatherData.name}  </h4>
          <h3 className='temp'>    {temp}°F  </h3> 
           <div className='weather-condition'> {weatherData.main && weatherData.weather[0].main}  </div>
             <div className='weather-detail-container'>
              <div className='weather-detail'>

                <div className='item'>
                    <div className='item-info'>
                      <div className='feels'>Feels like </div>
                    </div>
                      <div className='item-weather-info'>{weatherData.main && weatherData.main.feels_like}°F  </div>    
                               
                </div>

                <div className='item'>    
                    <div className='item-info'>
                      <div className='humidity'>Humidity:  </div>
                    </div>
                      <div className='item-weather-info'>{ weatherData.main && weatherData.main.humidity} % </div>
                </div>     
                                            
                 <div className='item'>
                    <div className='item-info'>
                      <div className='wind'> Wind: </div> 
                    </div>
                      <div className='item-weather-info'> { weatherData.main && weatherData.wind.speed} MPH  </div> 
                </div>

                

                 

              </div>     

            </div>
      </div> 
       

        </div>
      </div>
      
    </div>;

}

export default Weather;
