

import Navbar from '../../../Navbar';
import Footer from '../../../Footer';
import Sidebar from '../../../Sidebar';

import '../../../PageContainer.css'
import '../../../Cards.css'
import '../../../Transitions.css'
import './getcoins.css'


import {AnimatePresence, motion} from 'framer-motion'


import React, { useState, useEffect } from 'react';
import { Button } from '../../../Button';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from "react-flexbox-grid";


import Aos from 'aos';
import 'aos/dist/aos.css';


function Getcoins() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  
    const showButton = () => {
        if (window.innerWidth <= 960) {
        setButton(false);
        } else {
        setButton(true);
        }
    };
  
    useEffect(() => {
        showButton();
    }, []);
  
    window.addEventListener('resize', showButton);
  
  const [isVisible, setVisible] = React.useState(false);
  useEffect(() => {
  Aos.init({
    duration: 600,
  
    
    easing: 'ease',
   
  });
  }, []);
  
  
  const [isActive, setActive] = useState("false");
  
  const handleToggle = () => {
  setActive(!isActive);
  };
  
  const pageTransition={
    in:{
        opacity:1
    },
    out:{
        opacity:0
  
    }
  }
  
      return (
          <div>
  
              <div className='getcoins'> 
              <Sidebar/>
                <div className="page-container">
                  <div className='layout'>
                      <div className='page-contents'>
  
                        <Navbar/>
            
  
      
  
  
  
  
  <img src= './images/getcoins/card/hero3.png' data-aos="fade-down"/>    
  
  <motion.div    
  data-aos="fadeup"
   >
  
    <h3>Overview</h3>
    <div className='grid'>
            <div className="row">
              <div className='col'>
                <div className='container'>
                  <div className='text-container'> 
                    <div class='heading-title'>Client</div>

                      <li>
                      GetCoins is a company that allows users to <span className=' highlight-text shown'> buy/sell crytocurrency via their ATM machine & mobile app platform.</span> 
                      </li> 
                      
                  </div>
                </div>
                </div>
            <div class='col'>
              <div className='container'>
                <div class='heading-title'>Client's Request </div>
                  <li>
                      The client's request was to rebrand their mobile app, which functioned as a wallet for trading cryptocurrency.
                  
                  </li>
             </div>
            </div>
  
            <div class='col'>
              <div className='container'>
  
              < div class='heading-title'>My Role</div>
                  <li> UI Designer</li> 
  
               <div class='heading-title'>Timeline</div>
                  <li>February 16 - April 9(2022)</li> 
  
             <div class='heading-title'>Tools</div>
                  <li>Figma</li> 

  
            </div>
            </div>
  
    </div>
  


  <h1 className='getcoins'> Contact for more information. </h1>
  </div>
  </motion.div>

  {/* <div data-aos="fadeup" >
  
   <div className='container'>
    <span class='tag blue'>Before</span>
      <img src= './images//getcoins/before/before.png' className='wireframe' />

  </div>
  </div>

  <div data-aos="fadeup" >
  
  <div className='container'>
   <span class='tag blue'>After</span>
     <img src= './images//getcoins/before/before.png' className='wireframe' />

 </div>
 </div>
   */}
  
  <Footer/>
  
  
  </div>
  
  </div>
  </div>
  
  </div>
  
  </div>
      )
  }
  

export default Getcoins